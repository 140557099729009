






















































































import Vue from "vue";
import { mapState } from "vuex";
import moment from "moment";
import { dispatch, ListQuery, Notification } from "@/store";

export default Vue.extend({
  data() {
    return {
      moment,
      loading: false,
      form: this.$form.createForm(this),
      sectionList: [],
      columns: [
        {
          title: "消息 ID",
          key: "id",
          dataIndex: "id",
          width: "70px"
        },
        {
          title: "标题",
          key: "title",
          dataIndex: "title",
          width: "150px"
        },
        {
          title: "内容",
          key: "content",
          dataIndex: "content",
          scopedSlots: { customRender: "content" }
        },
        {
          title: "发布时间",
          key: "publish_at",
          dataIndex: "publish_at",
          scopedSlots: { customRender: "time" },
          width: "170px"
        },
        {
          title: "发布范围",
          key: "type",
          dataIndex: "type",
          customRender: (text: string) =>
            text === "public" ? "全员公开" : "指定用户",
          width: "80px"
        },
        {
          title: "操作",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          align: "center",
          width: "100px"
        }
      ]
    };
  },
  computed: {
    ...mapState("notifications", {
      list: "list",
      query: "query",
      count: "count"
    })
  },
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .notificationsGetList(query)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onFilterChange() {
      const filter = this.form.getFieldsValue();
      this.getList({
        ...this.query,
        ...filter,
        page_number: 1
      });
    },
    onReset() {
      this.form.resetFields();
      const filter = this.form.getFieldsValue();
      this.getList({
        ...this.query,
        ...filter,
        page_number: 1
      });
    },
    onTableChange(page: any, filters: any, sorter: any) {
      let ordering =
        sorter.order === "ascend" ? "-" + (sorter.columnKey || "") : "";
      ordering = sorter.order === "descend" ? sorter.columnKey || "" : ordering;
      this.getList({
        ...this.query,
        page_number: page.current,
        page_size: page.pageSize,
        ordering: ordering || this.query.ordering,
        status: (filters.status && filters.status[0]) || this.query.status
      });
    },
    onEdit(notification: Notification) {
      this.$router.push(this.$paths.notifications + "/" + notification.id);
    },
    onDelete(notification: Notification) {
      this.$confirm({
        title: `确认删除此消息？`,
        content: "消息删除后，收到消息的用户将无法再看到此消息。",
        okText: "删除",
        onOk: () => {
          dispatch.notificationsDelete(notification).then(() => {
            this.$message.success("消息已删除");
            if (this.list.length === 1) {
              this.getList({
                ...this.query,
                page_number: this.query.page_number - 1 || 1
              });
            } else {
              this.getList(this.query);
            }
          });
        }
      });
    }
  },
  mounted() {
    this.getList(this.query);
  }
});
