var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notification-list"},[_c('div',{staticClass:"header"},[_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$route.name)+" "),_c('a-button',{attrs:{"type":"primary","shape":"round","icon":"plus"},on:{"click":function($event){return _vm.$router.push(_vm.$paths.notificationsAdd)}}},[_vm._v(" 添加消息 ")])],1),_c('a-divider'),_c('a-form',{staticClass:"form",attrs:{"form":_vm.form}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":"发布方"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user_ids__is_null', { initialValue: 'false' }]),expression:"['user_ids__is_null', { initialValue: 'false' }]"}],staticClass:"form-field",staticStyle:{"width":"150px"},on:{"select":_vm.onFilterChange}},[_c('a-select-option',{attrs:{"value":"false"}},[_vm._v(" 管理员 ")]),_c('a-select-option',{attrs:{"value":"true"}},[_vm._v(" 系统触发 ")])],1)],1),_c('div',{staticClass:"actions"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onFilterChange}},[_vm._v(" 确认筛选 ")]),_c('a-button',{on:{"click":_vm.onReset}},[_vm._v(" 清空条件 ")])],1)],1)],1),_c('div',{staticClass:"content"},[_c('a-table',{staticClass:"table",attrs:{"columns":_vm.columns,"data-source":_vm.list,"loading":_vm.loading,"pagination":{
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: function (total) { return ("总计 " + total + " 条"); },
        current: Number(_vm.query.page_number),
        pageSize: Number(_vm.query.page_size),
        total: Number(_vm.count)
      },"rowKey":function (record) { return record.id; },"size":"middle"},on:{"change":_vm.onTableChange},scopedSlots:_vm._u([{key:"content",fn:function(text){return [_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(text)}})]}},{key:"time",fn:function(text){return [_c('span',{staticClass:"time",class:{ active: _vm.moment().isBefore(_vm.moment(text)) }},[_vm._v(" "+_vm._s(_vm._f("timeFormat")(text))+" ")])]}},{key:"actions",fn:function(text, record){return [_c('a-button',{attrs:{"icon":"edit","disabled":!record.user_ids},on:{"click":function($event){return _vm.onEdit(record)}}}),_vm._v(" "),_c('a-button',{attrs:{"icon":"delete","disabled":_vm.moment().isAfter(_vm.moment(record.publish_at))},on:{"click":function($event){return _vm.onDelete(record)}}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }